<template>
  <div class="grid">
    <div class="col-12">
      <div class="font-medium text-3xl text-900 mb-3">
        <span class="text-primary text-5xl">/</span> Perfil
      </div>
      <div class="text-500 mb-5">Preencha os campos abaixo</div>

      <Toast />

      <div class="card p-fluid w-full" style="max-width: 550px">
        <h5 class="mb-4">Meus dados</h5>

        <form @submit.prevent="saveProfile">
          <div class="field">
            <label for="name">Nome</label>
            <InputText
              id="name"
              v-model.trim="profile.name"
              required="true"
              autofocus
              autocomplete="off"
            />
          </div>

          <div class="field">
            <label for="email">E-mail</label>
            <InputText
              id="email"
              v-model.trim="profile.email"
              disabled
              required="true"
            />
          </div>

          <div class="flex mt-5">
            <Button
              label="Salvar perfil"
              icon="pi pi-check"
              class="p-button-primary w-6"
            />
          </div>
        </form>
      </div>

      <div class="card p-fluid w-full" style="max-width: 550px">
        <h5 class="mb-4">Senha</h5>

        <form @submit.prevent="updatePassword">
          <div class="field w-6">
            <label for="password">Senha atual</label>
            <InputText
              id="password"
              type="password"
              v-model.trim="passwords.password"
              autocomplete="off"
            />
          </div>

          <div class="grid">
            <div class="field col-6">
              <label for="newpassword">Nova Senha</label>
              <InputText
                id="newpassword"
                type="password"
                v-model.trim="passwords.newPassword"
                autocomplete="off"
              />
            </div>

            <div class="field col-6">
              <label for="repeatpassword">Repita a senha</label>
              <InputText
                id="repeatpassword"
                type="password"
                v-model.trim="passwords.repeatPassword"
                autocomplete="off"
              />
            </div>
          </div>

          <div class="flex mt-5">
            <Button
              label="Atualizar senha"
              icon="pi pi-check"
              class="p-button-primary w-6"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { getProfile, updatePassword, saveProfile } from '@/services/auth'
import EventBus from '@/AppEventBus'

export default {
  data() {
    return {
      profile: {},
      passwords: {}
    }
  },
  async mounted() {
    this.profile = await getProfile()
  },
  methods: {
    async saveProfile() {
      this.profile = await saveProfile(this.profile)
      this.$toast.add({
        severity: 'success',
        summary: 'Perfil atualizado com sucesso!',
        life: 3000
      })
    },
    async updatePassword() {
      try {
        await updatePassword(this.passwords)
        this.$toast.add({
          severity: 'success',
          summary: 'Senha atualizada com sucesso!',
          life: 3000
        })
      } catch ({ response }) {
        const { data } = response
        this.$noty.error(data.message)
      }
    }
  },
  watch: {
    profile() {
      EventBus.emit('updateProfile', this.profile)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
